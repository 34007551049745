html,
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

body {
  font-family: "DM Sans";
}

h2,
h3 {
  font-weight: 100;
  color: #f2e9fb;
  margin: 0;
}

button {
  border: none;
  background-color: #14b24e;
  color: #fff;
  padding: 0 2rem;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  font-family: "DM Sans";
}

input {
  font-family: "DM Sans";
}

p {
  font-weight: 100;
  opacity: 0.7;
  font-size: 1rem;
}

header nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem auto;
  width: 90%;
  margin-top: 1rem;
  align-items: center;
}

header nav .logo {
  display: flex;
  flex-direction: row;
}

header nav img {
  width: 3rem;
}

header nav .logo p {
  font-size: 1.5rem;
  opacity: 1;
  letter-spacing: 0.06rem;
}

header section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5%;
}

header section .section_info {
  width: 50%;
}

header section .section_image {
  width: 50%;
  height: 80vh;
}

header section .section_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

header section .section_info {
  margin-top: 10rem;
}

header section h1 {
  font-size: 2.7rem;
  margin: 0;
}

header section p {
  font-weight: 100;
  line-height: 1.6rem;
  width: 90%;
  opacity: 0.7;
}

.input_container {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(102, 90, 114, 0.25);
  border-radius: 10px;
  width: auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 3rem;
}

input {
  border: none;
  background-color: transparent;
  padding: 1rem 2rem;
  width: 70%;
}

header section .attention {
  font-size: small;
}

footer p {
  text-align: center;
  margin-top: 2rem;
  font-size: small;
}

@media screen and (max-width: 600px) {
  header section {
    flex-direction: column;
  }

  header section .section_info {
    width: 100%;
    margin-top: 5rem;
  }

  header section .section_image {
    width: 100%;
    margin: 4rem auto;
  }

  header section .section_image img {
    height: auto;
    width: 130%;
    margin-left: -4.5rem;
  }

  header section h1 {
    font-size: 2.7rem;
  }

  .input_container {
    width: 100%;
    flex-direction: column;
  }

  .input_container input {
    width: 100%;
  }

  .input_container button {
    height: 2.5rem;
  }
}
